import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import TrashIcon from "../../../assets/TrashIcon";
import Checkbox from "../../../components/Checkbox";
import React, { useEffect, useMemo, useState } from "react";
import EditIcon from "../../../assets/EditIcon";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import {
  getOrderItems,
  getSingleOrderDetails,
  updateOrderItem,
} from "../../../api/orderManagement";
import AddProductPopup from "./AddProductPopup";
import {
  formatIndianRupees,
  formatIndianRupeesForDecimals,
} from "../../../utils/formatToIndianRupees";

function OrderTimelineTable({
  data,
  setTimelineData,
  handleDelete,
  handleQuantity,
  setTableData,
  projectDetails,
  orderDetails,
  setOrderDetails,
}: any) {
  const { id } = useParams();
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [openAddProductModal, setOpenAddProductModal] =
    useState<boolean>(false);
  const [currentProject, setCurrentProject] = useState<any>("");
  const [checkedRows, setCheckedRows] = useState<{ [key: string]: boolean }>(
    {}
  );

  // Function to format the date coming from the response into DD/MM/YY format
  function parseDate(formattedDate: any) {
    if (formattedDate === null || formattedDate === undefined) return null;
    const [year, month, day] = formattedDate?.split("-")?.map(Number); // eslint-disable-line
    return new Date(year, month - 1, day); // Subtract 1 from month since it's 0-based
  }

  // Function to format the date into YYYY-MM-DD format to pass as response body
  function formatDate(inputDate: any) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  useEffect(() => {
    if (selectedDate) {
      const { productId, date } = selectedDate;
      const payload = {
        est_delivery_at: date,
      };

      updateOrderItem(id, productId, payload)
        .then((res: any) => {
          getOrderItems(id).then((res: any) => {
            setTableData(res.data);
          });
        })
        .catch((error) => console.error("Error : "));
    }
  }, [selectedDate]);

  // FUNCTION to get the checkbox values of all the items
  const getHeaderCheckboxValue = () => {
    const checkedValues = Object.values(checkedRows);

    if (checkedValues?.every((value) => value) && checkedValues?.length > 0)
      return 1;
    if (checkedValues?.some((value) => value) && checkedValues?.length > 0)
      return 2;
    return 0;
  };

  // FUNCTION To update all uncheckwd ids in the local storage
  const handleUncheckedItems = () => {
    const checkStatus = getHeaderCheckboxValue() > 0;
    const newCheckedRows: any = {};

    if (checkStatus) {
      const updatedSearches = data?.map((row: any) => {
        newCheckedRows[row?.id] = false;
        setSelectedItems([]);
        return row?.id;
      });

      localStorage.setItem(
        "unchecked-cart-items",
        JSON.stringify(updatedSearches)
      );
    } else {
      data.forEach((row: any) => {
        newCheckedRows[row?.id] = true;
        setSelectedItems((prev: any) => [...prev, row?.id]);
      });

      localStorage.removeItem("unchecked-cart-items");
    }
    setCheckedRows(newCheckedRows);
  };

  useEffect(() => {
    const initialCheckedRows: any = {};
    data?.forEach((item: any) => {
      initialCheckedRows[item.id] = true;
    });
    setCheckedRows(initialCheckedRows);
  }, []);

  let columns = [
    // {
    //   header: () => (
    //     <div className="flex justify-center items-center">
    //       <Checkbox
    //         checkValue={getHeaderCheckboxValue()}
    //         onClick={() => {
    //           handleUncheckedItems();
    //         }}
    //       />
    //     </div>
    //   ),
    //   accessorKey: "is_selected",
    //   cell: ({ row }: any) => (
    //     <div className="w-full flex justify-center px-1">
    //       <Checkbox
    //         checkValue={checkedRows[row?.original?.id] ? 1 : 0}
    //         onClick={() => {
    //           const newCheckedRows: any = { ...checkedRows };
    //           newCheckedRows[row?.original?.id] =
    //             !newCheckedRows[row?.original?.id];
    //           setCheckedRows(newCheckedRows);
    //           // COMMENTED due to software requirements, DO NOT DELETE IT YET
    //           // handleLocalStorage(newCheckedRows, row);
    //           let newSelectedItems = [...selectedItems];
    //           if (newSelectedItems.includes(row?.original?.id)) {
    //             newSelectedItems = newSelectedItems.filter(
    //               (item: any) => item !== row?.original?.id
    //             );
    //           } else {
    //             newSelectedItems.push(row?.original?.id);
    //           }
    //           setSelectedItems(newSelectedItems);
    //         }}
    //       />
    //     </div>
    //   ),
    // },
    {
      header: "No.",
      accessorKey: "",
      cell: ({ row }: any) => {
        return <p className="min-w-[30px]">{+row.id + 1}</p>;
      },
    },
    {
      header: "Product",
      accessorKey: "product.name",
      cell: ({ row, getValue }: any) => {
        return <p className="min-w-[150px]">{getValue()}</p>;
      },
    },
    {
      header: "Variant",
      accessorKey: "product.code",
      cell: ({ getValue }: any) => {
        return <p className="min-w-[80px]">{getValue()}</p>;
      },
    },
    // {
    //   header: "",
    //   accessorKey: "product.primary_image",
    //   cell: (info: any) => (
    //     <div className="min-w-[50px]">
    //       {/* <Image
    //         width={50}
    //         height={50}
    //         style={{ margin: "auto", height: "auto" }}
    //         src={`${process.env.NEXT_PUBLIC_BUCKET}/${info.getValue()}`}
    //         alt=""
    //       /> */}
    //     </div>
    //   ),
    // },
    {
      header: "Light colour",
      accessorKey: "product_variants",
      cell: ({ getValue }: any) => {
        const variants = getValue();
        const value = variants?.map((el: any) => {
          if (
            el.variant.name === "Light Colour" ||
            el.variant.name === "Light Color"
          )
            return el.variant.option.name;
        });
        return (
          <p className="min-w-[100px]">
            {value.every((option: any) => option === undefined) ? "-" : value}
          </p>
        );
      },
    },
    {
      header: "Body colour",
      accessorKey: "product_variants",
      cell: ({ getValue }: any) => {
        const variants = getValue();
        const value = variants?.map((el: any) => {
          if (
            el.variant.name === "Body Colour" ||
            el.variant.name === "Body Color"
          )
            return el.variant.option.name;
        });
        return (
          <p className="min-w-[100px]">
            {value.every((option: any) => option === undefined) ? "-" : value}
          </p>
        );
      },
    },
    {
      header: "Reflector colour",
      accessorKey: "product_variants",
      cell: ({ getValue }: any) => {
        const variants = getValue();
        const value = variants?.map((el: any) => {
          if (
            el.variant.name === "Reflector Body Colour" ||
            el.variant.name === "Reflector Body Color"
          )
            return el.variant.option.name;
        });
        return (
          <p className="min-w-[100px]">
            {value.every((option: any) => option === undefined) ? "-" : value}
          </p>
        );
      },
    },
    {
      header: "Power",
      accessorKey: "product_variants",
      cell: ({ getValue }: any) => {
        const variants = getValue();
        const value = variants?.map((el: any) => {
          if (
            el.variant.name === "Power" &&
            (el.variant.option.name || el.variant.option.name)
          ) {
            return (el.variant.option.name || el.variant.option.name) + "W";
          }
        });
        return (
          <p className="min-w-[100px]">
            {value.every((option: any) => option === undefined) ? "-" : value}
          </p>
        );
      },
    },
    {
      header: "Beam°",
      accessorKey: "product_variants",
      cell: ({ getValue }: any) => {
        const variants = getValue();
        const value = variants?.map((el: any) => {
          if (el.variant.name === "Beam Angle")
            return el.variant.option.name + "°";
        });
        return (
          <p className="min-w-[100px]">
            {value.every((option: any) => option === undefined) ? "-" : value}
          </p>
        );
      },
    },
    {
      header: "Type",
      accessorKey: "product_variants",
      cell: ({ getValue }: any) => {
        const variants = getValue();
        const value = variants?.map((el: any) => {
          if (el.variant.name === "Lighting Controls")
            return el.variant.option.name;
        });
        return (
          <p className="min-w-[100px]">
            {value.every((option: any) => option === undefined) ? "-" : value}
          </p>
        );
      },
    },
    {
      header: "Unit Price",
      accessorKey: "unit_price",
      cell: ({ getValue }: any) => {
        let price = formatIndianRupeesForDecimals(parseFloat(getValue()));
        return (
          <div className="flex gap-2 justify-center min-w-[80px]">
            <p>{price}</p>
          </div>
        );
      },
    },
    {
      header: "Qty",
      accessorKey: "quantity",
      cell: ({ row, getValue }: any) => {
        return (
          <p className="w-8 flex justify-center items-center">{getValue()}</p>
        );
      },
    },
    {
      header: "Total Price",
      accessorKey: "grand_total",
      cell: ({ getValue }: any) => {
        let price = formatIndianRupeesForDecimals(parseFloat(getValue()));
        return (
          <div className="flex gap-2 justify-center min-w-[80px]">
            <p>{price}</p>
          </div>
        );
      },
    },
    {
      header: "Timeline",
      accessorKey: "timeline",
      cell: ({ row, getValue }: any) => {
        const est_date: any = parseDate(row?.original?.est_delivery_at);
        return (
          <DatePicker
            selected={est_date}
            minDate={new Date()}
            onChange={(currentDate: any) => {
              const formattedDate: any = formatDate(currentDate);
              setSelectedDate({
                date: formattedDate,
                productId: row.original.id,
              });
            }}
            className="text-center w-[85px] z-[100]"
            dateFormat={"dd/MM/yy"}
            placeholderText="DD/MM/YY"
            dayClassName={(date) =>
              est_date && date.toDateString() === est_date?.toDateString()
                ? "bg-pot-yellow text-[#000]"
                : null
            }
          />
        );
      },
    },
    {
      header: "Actions",
      accessorKey: "actions",
      cell: ({ row, getValue }: any) => {
        return (
          <div className="flex gap-2">
            <div
              className="flex justify-center items-center mx-1 cursor-pointer"
              onClick={() => {
                setCurrentProject(row.original);
                setOpenAddProductModal(true);
              }}
            >
              <EditIcon />
            </div>
            <div
              className="flex justify-center items-center mx-1 cursor-pointer"
              onClick={() => {
                handleDelete(id, row.original.id);
                setTimeout(() => {
                  getSingleOrderDetails(orderDetails?.id)
                    .then((res) => {
                      setOrderDetails(res?.data?.order);
                    })
                    .catch((err) => {
                      console.log(err, "err");
                    });
                }, 500);
              }}
            >
              <TrashIcon />
            </div>
          </div>
        );
      },
    },
  ];

  if (orderDetails?.owner === "PROJECT") {
    columns.splice(1, 0, {
      header: "Area",
      accessorKey: "area_id",
      cell: ({ row, getValue }: any) => {
        return (
          <p className="min-w-[100px]">{row?.original?.area_name || "-"}</p>
        );
      },
    });
  }
  columns = useMemo(() => columns, [data]);
  const tableData = useMemo(() => data || [], [data]);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="w-full border-collapse text-center table-auto">
      <thead className="bg-pot-grey3">
        {table.getHeaderGroups().map((headerGroup: any) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header: any, index: any) => {
              return (
                <th
                  key={index}
                  colSpan={header.colSpan}
                  className={`font-gilroy-bold whitespace-nowrap border ${
                    index === 0
                      ? "sticky left-0 z-[1] bg-pot-grey3 "
                      : index === 1
                      ? "sticky left-[45px] z-[1] bg-pot-grey3 "
                      : ""
                  }`}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className={`"w-full flex justify-center text-base py-2`}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table?.getRowModel().rows.map((row: any) => {
          return (
            <tr
              key={row.id}
              className={`${
                row.getCanExpand()
                  ? row.original.paymentStatus
                    ? "bg-[#F2F6FF]"
                    : "bg-[#FFFCF1]"
                  : "bg-white"
              }`}
            >
              {row.getVisibleCells().map((cell: any, index: any) => {
                return (
                  <td
                    key={index}
                    className={` border p-2  ${
                      index === 0
                        ? "sticky left-0 z-[1] bg-white "
                        : index === 1
                        ? "sticky left-[45px] z-[1] bg-white"
                        : ""
                    }`}
                  >
                    <div className="w-full flex justify-center items-center">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}

        {openAddProductModal && (
          <AddProductPopup
            handleCancel={() => {
              setOpenAddProductModal(false);
              setCurrentProject({});
            }}
            projectDetails={projectDetails}
            orderDetails={orderDetails}
            currentProject={currentProject}
            setTableData={setTableData}
            setOrderDetails={setOrderDetails}
          />
        )}
      </tbody>
    </table>
  );
}

export default OrderTimelineTable;
