import React, { useEffect, useState } from "react";
import Thumbsup from "../../../assets/Thumbsup";
import CartIcon from "../../../assets/CartIcon";
import HourGlassIcon from "../../../assets/HourGlassIcon";
import TickIcon from "../../../assets/TickIcon";
import CircledTickIcon from "../../../assets/CircledTickIcon";
import ClockIcon from "../../../assets/ClockIcon";
import CrossIcon from "../../../assets/CrossIcon";
import { useRecoilState } from "recoil";
import { filterByStatusAtom } from "../../../atom";

type CardPropType = {
  orderStatus?: string;
  orderStatusSummary: {
    PENDING: { count: number };
    DRAFT: { count: number };
    AWAITING_TIMELINE: { count: number };
    AWAITING_TIMELINE_CONFIRMATION: { count: number };
    PROCESSING: { count: number };
    OUT_FOR_DELIVERY: { count: number };
    PARTIALLY_DELIVERED: { count: number };
    DELIVERED: { count: number };
    CANCELLED: { count: number };
    REFUNDED: { count: number };
  };
};

const iconColors: Record<string, string> = {
  AWAITING_TIMELINE: "#EADA4A",
  AWAITING_TIMELINE_CONFIRMATION: "#C3F090",
  CANCELLED: "#EBA5AF",
  DELIVERED: "#C3F090",
  PROCESSING: "#C3E1F8",
  REFUNDED: "#EBA5AF",
  PENDING: "#EBA5AF",
  DRAFT: "#D6D6D6",
  OUT_FOR_DELIVERY: "#EBA5AF",
  PARTIALLY_DELIVERED: "#EBA5AF",
};

const Card = ({ orderStatus, orderStatusSummary }: CardPropType) => {
  const [selectedStatus, setSelectedStatus] =
    useRecoilState(filterByStatusAtom);
  const [isHovered, setIsHovered] = useState<string | null>(null);

  const handleStatusClick = (status: string) => {
    setSelectedStatus(status);
  };
  const handleMouseEnter = (status: string) => {
    setIsHovered(status);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  useEffect(() => {
    setSelectedStatus("PENDING");
  }, []);

  return (
    <>
      {Object.entries(orderStatusSummary).map(([status, data]) => {
        const condition = isHovered === status || selectedStatus === status;
        const formattedValue = status ? status.replace(/_/g, " ") : "-";
        return (
          <div
            key={status}
            style={{
              backgroundColor: condition ? iconColors[status] : "#FCFCFC",
            }}
            className={`w-full flex flex-col cursor-pointer`}
            onClick={() => handleStatusClick(status)}
            onMouseEnter={() => handleMouseEnter(status)}
            onMouseLeave={handleMouseLeave}
          >
            <div
              style={{ borderColor: iconColors[status] || "#000" }}
              className={`border-l-2 py-3 px-3 w-[10vw]`}
            >
              <div className="flex justify-between items-center">
                <p
                  className={`font-medium text-[20px] ${
                    condition ? "text-[#ffffff]" : "text-black"
                  }`}
                >
                  {data.count}
                </p>
                {status == "AWAITING_TIMELINE" ? (
                  <ClockIcon color={condition ? "#ffffff" : ""} />
                ) : status == "AWAITING_TIMELINE_CONFIRMATION" ? (
                  <Thumbsup color={condition ? "#ffffff" : ""} />
                ) : status == "CANCELLED" ? (
                  <CrossIcon color={condition ? "#ffffff" : "#EBA5AF"} />
                ) : status == "DELIVERED" ? (
                  <CircledTickIcon color={condition ? "#ffffff" : ""} />
                ) : status == "PROCESSING" ? (
                  <HourGlassIcon color={condition ? "#ffffff" : ""} />
                ) : status == "REFUNDED" ? (
                  <CrossIcon color={condition ? "#ffffff" : ""} />
                ) : status == "PENDING" ? (
                  <CrossIcon color={condition ? "#ffffff" : ""} />
                ) : status == "DRAFT" ? (
                  <CrossIcon color={condition ? "#ffffff" : ""} />
                ) : status == "OUT_FOR_DELIVERY" ? (
                  <CrossIcon color={condition ? "#ffffff" : ""} />
                ) : status == "PARTIALLY_DELIVERED" ? (
                  <CrossIcon color={condition ? "#ffffff" : ""} />
                ) : (
                  ""
                )}
              </div>
              <p
                className={`text-[11px] text-left truncate ${
                  condition ? "text-[#ffffff]" : "text-[#AEAEAE]"
                }`}
                title={formattedValue}
              >
                {formattedValue}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Card;
