import React, { useEffect, useState } from "react";
import Modal from "../../../components/common/Modal";
import CancelIcon from "../../../assets/svg/close.svg";
import SearchableDropdown from "../../../components/SearchableDropdown";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import { getProduct, getSingleProduct } from "../../../api/product";
import {
  getOrderItems,
  getSingleOrderDetails,
  postOrderItem,
  updateOrderItem,
} from "../../../api/orderManagement";
import { toast } from "react-toastify";
import loadingSpinner from "../../../assets/images/LoadingSpinner.gif";
import { formatIndianRupees } from "../../../utils/formatToIndianRupees";

// Function to structure the data needed to pass down as options for searchable dropdowns
const structureData = (
  data: any,
  labelKey: string,
  valueKey: string,
  additionalKey?: string,
  extraKey: string = ""
) => {
  let options = data
    ? data.map((item: any) => {
        let priceExists =
          additionalKey !== undefined && item[additionalKey] !== undefined
            ? true
            : false;
        let codeExists =
          extraKey !== "" && item[extraKey] !== undefined ? true : false;
        // Function to format price into price with commas (Assuming formatIndianRupees is a valid function)
        let price = additionalKey && formatIndianRupees(item[additionalKey]);
        let labelValue =
          additionalKey && priceExists
            ? item[labelKey] +
              (codeExists ? " | " + item[extraKey] : "") +
              " | " +
              price
            : item[labelKey];
        return {
          label: labelValue,
          value: item[valueKey],
        };
      })
    : [];
  return options;
};

type AddProductPopupType = {
  handleCancel: () => void;
  orderDetails?: any;
  projectDetails?: any;
  currentProject?: any;
  setTableData?: any;
  setOrderDetails?: any;
};

const AddProductPopup = ({
  handleCancel,
  orderDetails,
  projectDetails,
  currentProject,
  setTableData,
  setOrderDetails,
}: AddProductPopupType) => {
  const [formData, setFormData] = useState<any>({});
  const [searchInput, setSearchInput] = useState<any>({});
  const [dropdownOptions, setDropdownOptions] = useState<any>({});
  const [originalOptions, setOriginalOptions] = useState<any>({});
  const [productName, setProductName] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateFloor, setUpdateFloor] = useState<boolean>(false);

  const getAllProducts = () => {
    getProduct({ search: searchInput?.product, length: 50 })
      .then((res: any) => {
        let products = structureData(
          res?.data?.product,
          "name",
          "id",
          "base_price",
          "code"
        );
        setOriginalOptions((prev: any) => ({
          ...prev,
          product: products,
        }));
        if (!searchInput.product) {
          setDropdownOptions((prev: any) => ({
            ...prev,
            product: products,
          }));
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      });
  };

  useEffect(() => {
    setFormData((prev: any) => ({
      quantity: 1,
    }));
  }, []);

  useEffect(() => {
    getAllProducts();
  }, [searchInput?.product]);

  // Edit product/ order item
  useEffect(() => {
    if (currentProject) {
      setFormData((prev: any) => {
        return {
          product: currentProject?.product_id,
          floor: currentProject?.floor_id,
          area: currentProject?.area_id,
          quantity: currentProject?.quantity,
        };
      });
      const newProductName = [
        {
          label: currentProject.product.name,
          value: currentProject.product.id,
        },
      ];

      setProductName(newProductName);
      currentProject?.product_variants.map((variant: any) => {
        const labelKey = variant?.variant?.name || variant?.variant?.name;
        const valueKey = variant?.variant?.option?.id;

        setFormData((prev: any) => {
          return {
            ...prev,
            [labelKey]: valueKey,
          };
        });
      });
    }
  }, [currentProject]);

  // Get floor options whenever we get the props from the parent component
  useEffect(() => {
    if (projectDetails?.floors) {
      const floorOptions = structureData(projectDetails?.floors, "name", "id");
      setDropdownOptions((prev: any) => ({
        ...prev,
        floor: floorOptions,
      }));
      setOriginalOptions((prev: any) => ({
        ...prev,
        floor: floorOptions,
      }));
    }
  }, [projectDetails?.floors]);

  // Get area options depending upon the selected floor
  useEffect(() => {
    // Delete area if the user updates the selected floor
    updateFloor && delete formData?.area;

    if (projectDetails?.floors && formData?.floor) {
      const selectedFloor = projectDetails.floors.find(
        (floor: any) => floor.id === formData?.floor
      );
      if (selectedFloor) {
        const areasOptions = selectedFloor.areas.map((area: any) => ({
          label: area.name,
          value: area.id,
        }));
        setOriginalOptions((prev: any) => ({
          ...prev,
          area: areasOptions,
        }));
      }
    }
  }, [formData?.floor]);

  // Update dropdown options for area depending on originalOptions.area
  useEffect(() => {
    setDropdownOptions((prev: any) => ({
      ...prev,
      area: originalOptions.area,
    }));
  }, [originalOptions.area]);

  // Get variant options depending upon the selected product
  useEffect(() => {
    if (formData?.product) {
      // Delete selected variants if product is updated
      delete formData?.product_variants;
      setIsLoading(true);
      getSingleProduct(formData?.product)
        .then((res: any) => {
          let newVariants: any = {};

          res?.data?.product?.stages.forEach((stage: any) => {
            stage.variants.forEach((variant: any) => {
              const key = variant.name;
              const value = variant?.options.map((option: any) => ({
                name: option.name || option.name,
                id: option.id,
                price: option.price,
              }));

              newVariants[key] = {
                id: variant.id,
                options: structureData(value, "name", "id", "price"),
              };
            });
          });

          // Update the dropdownOptions.variants with the new variants
          setDropdownOptions((prev: any) => ({
            ...prev,
            variant: newVariants,
          }));

          // Update the originalOptions.variants as well
          setOriginalOptions((prev: any) => ({
            ...prev,
            variant: newVariants,
          }));
        })
        .catch((error: any) => console.error(error, "error"))
        .finally(() => setIsLoading(false));
    }
  }, [formData?.product]);

  // SEARCHING DROPDOWN OPTIONS IS REMAINING
  const filterOptions = (inputVal: any, key: any) => {
    const normalFilterKeys = ["floor", "area", "product"];
    if (normalFilterKeys.includes(key)) {
      const newOptions = originalOptions[key]?.filter((item: any) =>
        item.label.toLowerCase().includes(inputVal.toLowerCase())
      );
      setDropdownOptions((prevOptions: any) => ({
        ...prevOptions,
        [key]: newOptions,
      }));
    } else {
      const newOptions = originalOptions?.variant[key]?.options?.filter(
        (item: any) => item.label.toLowerCase().includes(inputVal.toLowerCase())
      );

      setDropdownOptions((prevOptions: any) => ({
        ...prevOptions,
        variant: {
          ...prevOptions.variant,
          [key]: { id: prevOptions.variant[key].id, options: newOptions },
        },
      }));
    }
  };

  useEffect(() => {
    Object.keys(searchInput)?.map((key: any) => {
      if (searchInput[key] !== undefined && searchInput[key].length >= 0) {
        filterOptions(searchInput[key], key);
      }
    });
  }, [searchInput]);

  const handleSubmit = () => {
    if (currentProject) {
      const payload: any = {
        quantity: formData?.quantity,
      };

      if (formData?.floor) {
        payload.floor_id = formData?.floor;
      }

      if (formData?.area) {
        payload.area_id = formData?.area;
      }
      updateOrderItem(orderDetails.id, currentProject.id, payload)
        .then((res: any) => {
          toast.success("Item updated successfully");
          getOrderItems(orderDetails.id).then((res: any) => {
            setTableData(res.data);
          });
          getSingleOrderDetails(orderDetails.id)
            .then((res) => {
              setOrderDetails(res?.data?.order);
            })
            .catch((err) => {
              console.log(err, "err");
            });
          handleCancel();
        })
        .catch((error) => {
          if (error.data.errors.quantity) {
            setErrorMessage((prev: any) => ({
              ...prev,
              quantity: error.data.errors.quantity[0],
            }));
          }
          if (error.data.errors.product_variants) {
            setErrorMessage((prev: any) => ({
              ...prev,
              product_variants: error.data.errors.product_variants[0],
            }));
          }
        });
    } else {
      const payload: any = {
        product_id: formData?.product,
        floor_id: formData?.floor,
        area_id: formData?.area,
        quantity: formData?.quantity,
        product_variants: formData?.product_variants,
      };
      postOrderItem(orderDetails.id, payload)
        .then((res: any) => {
          toast.success("Item added successfully");
          getOrderItems(orderDetails.id).then((res: any) => {
            setTableData(res.data);
          });
          getSingleOrderDetails(orderDetails.id)
            .then((res) => {
              setOrderDetails(res?.data?.order);
            })
            .catch((err) => {
              console.log(err, "err");
            });
          handleCancel();
        })
        .catch((error: any) => {
          if (error.data.errors.quantity) {
            setErrorMessage((prev: any) => ({
              ...prev,
              quantity: error.data.errors.quantity[0],
            }));
          }
          if (error.data.errors.product_variants) {
            setErrorMessage((prev: any) => ({
              ...prev,
              product_variants: error.data.errors.product_variants[0],
            }));
          }
          if (error.data.errors.area_id) {
            setErrorMessage((prev: any) => ({
              ...prev,
              area_id: error.data.errors.area_id[0],
            }));
          }
        });
    }
  };

  const handleChange = (
    key: string,
    value: any,
    search: boolean = true,
    varId: string = ""
  ) => {
    setFormData((prev: any) => {
      if (varId.length > 1) {
        // To check if the variant
        const productVariants = Array.isArray(prev.product_variants)
          ? [...prev.product_variants]
          : [];

        const variantIndex = productVariants.findIndex(
          (variant: any) => variant.variant_id === varId
        );

        if (variantIndex !== -1) {
          // If the variant exists, remove it
          productVariants.splice(variantIndex, 1);
        }

        productVariants.push({
          variant_id: varId,
          option_id: value,
        });

        return {
          ...prev,
          product_variants: productVariants,
        };
      } else {
        return {
          ...prev,
          [key]: value,
        };
      }
    });
    search && handleSearch(key, "");
  };

  const handleSearch = (key: string, value: any) => {
    setSearchInput((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Modal
      allowScroll
      maxHeight="max-h-[90vh]"
      header={currentProject ? "Edit Product" : "Add a Product"}
      footer={
        <div onClick={handleSubmit}>
          <Button
            variant="secondary"
            label={currentProject ? "Edit item" : "Add to cart"}
          />
        </div>
      }
      handleCancel={handleCancel}
    >
      <div className="min-h-[70vh]  w-full flex flex-col">
        {orderDetails?.owner === "PROJECT" && (
          <>
            <div className="flex w-full">
              <SearchableDropdown
                label="Select floor:"
                options={dropdownOptions?.floor}
                value={formData?.floor}
                onChange={(value) => {
                  setUpdateFloor(true);
                  handleChange("floor", value);
                }}
                userSearch={searchInput?.floor}
                setUserSearch={(value) => handleSearch("floor", value)}
                fullWidth
              />
            </div>
            <div className="flex w-full mt-4">
              <SearchableDropdown
                label="Select area:"
                options={dropdownOptions?.area}
                value={formData?.area}
                onChange={(value) => handleChange("area", value)}
                userSearch={searchInput?.area}
                setUserSearch={(value) => handleSearch("area", value)}
                disable={!formData?.floor}
                fullWidth
              />
            </div>
          </>
        )}
        {errorMessage?.area_id && (
          <p className="flex h-0 px-1 text-left font-light text-xs text-red-700">
            {errorMessage?.area_id}
          </p>
        )}
        <div
          className={`flex w-full ${
            orderDetails?.owner === "PROJECT" ? "mt-4" : "mt-0"
          }`}
        >
          <SearchableDropdown
            label="Select product:"
            options={
              productName[0]?.label ? productName : dropdownOptions?.product
            }
            value={formData?.product}
            onChange={(value) => handleChange("product", value)}
            userSearch={searchInput?.product}
            setUserSearch={(value) => handleSearch("product", value)}
            limit={50}
            fullWidth
            disable={currentProject}
          />
        </div>
        {errorMessage?.product_variants && (
          <p className="flex h-0 px-1 text-left font-light text-xs text-red-700">
            {errorMessage?.product_variants}
          </p>
        )}
        {/* Variants dropdowns based on the selected project */}
        <div className="flex flex-wrap gap-x-4">
          {/* Display loading spinner while the variants are being fetched and displayed */}
          {isLoading && (
            <div className="flex justify-center items-center w-36 h-24 m-auto">
              <div className="text-md font-medium text-gray-900 bg-white rounded-lg inline-flex items-center">
                <img
                  src={loadingSpinner}
                  alt="spinner"
                  className="h-[20%] w-[20%]"
                />
                <p>Loading...</p>
              </div>
            </div>
          )}
          {/* Txt when there is no variant available in the selectedproduct */}
          {dropdownOptions?.variant &&
            Object.values(dropdownOptions?.variant)?.length === 0 && (
              <div className="mt-4">
                <p>No variants in this product</p>
              </div>
            )}
          {/* Display the variants for the selected product */}
          {dropdownOptions?.variant &&
            !isLoading &&
            Object.keys(dropdownOptions?.variant).map(
              (variantKey: string, index: number) => {
                const variantValues = dropdownOptions.variant[variantKey];
                const variantId = variantValues?.id;
                const variantOptions = variantValues?.options;
                const isSmall =
                  variantOptions &&
                  variantOptions[0] !== undefined &&
                  variantOptions[0]?.label.length < 25;
                return (
                  <div
                    className={`flex mt-4 ${isSmall ? "w-[48.5%]" : "w-full"}`}
                    key={variantKey}
                  >
                    <SearchableDropdown
                      label={variantKey}
                      options={variantOptions}
                      value={formData[variantKey]}
                      onChange={(value) =>
                        handleChange(variantKey, value, true, variantId)
                      }
                      userSearch={searchInput[variantKey]}
                      setUserSearch={(value) => handleSearch(variantKey, value)}
                      fullWidth
                      disable={currentProject}
                      placeholder={
                        currentProject
                          ? `Variant not selected`
                          : `Select an option`
                      }
                    />
                  </div>
                );
              }
            )}
        </div>
        <div className="flex w-[48%] flex-col mt-4">
          <InputField
            label="Quantity:"
            value={formData?.quantity}
            type="number"
            inputType="number"
            placeholder="Add quantity"
            onChange={(value) => handleChange("quantity", value, false)}
          />
          {errorMessage?.quantity && (
            <p className="flex h-0 px-1 text-left font-light text-xs text-red-700">
              {errorMessage?.quantity}
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddProductPopup;
