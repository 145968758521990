import React, { useRef, useState } from "react";
import editPen from "../../../assets/svg/pen.svg";
import linkIcon from "../../../assets/svg/linkicon.svg";
import useOnClickOutside from "../../../hooks/useClickOutside";
import { useRecoilValue } from "recoil";
import { MainAppSideBarExpandAtom } from "../../../atom";
import HipchatChevronDownIcon from "../../../assets/HipchatChevronDownIcon";
import { motion } from "framer-motion";
import EyeIcon from "../../../assets/svg-tsx/EyeIcon";
import { useNavigate } from "react-router-dom";

const OrderCreateUserDetails = ({ userData, formatDate }: any) => {
  const navigate = useNavigate();
  const statusOptions = [
    { value: "ONGOING", label: "ONGOING" },
    { value: "COMPLETED", label: "COMPLETED" },
  ];
  const [selected, setSelected] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useOnClickOutside(selectRef, () => setIsOpen(false));

  const handleOptionClick = (option: any) => {
    setSelected(option);
    setIsOpen(false);
  };

  return (
    <div className="text-start px-4 pt-2 py-4 h-full">
      <div className="w-full flex justify-between">
        <h1 className="text-pot-yellow font-medium text-sm">
          Customer Details:{" "}
        </h1>
        {/* Added eye icon which will redirect to the edit customer page */}
        {userData?.user?.id && (
          <div
            className="mr-2 flex justify-center items-center cursor-pointer"
            onClick={() => navigate(`/customers/${userData?.user?.id}`)}
          >
            <EyeIcon size="16" />
          </div>
        )}
      </div>
      <div className="flex flex-col space-y-1  text-start mt-2 text-[12px] leading-5">
        <div>
          <p className="text-[#AEAEAE]">Name:</p>
          <p>
            {userData?.user?.name !== undefined && userData?.user?.name !== ""
              ? userData?.user?.name
              : "-"}
          </p>
        </div>
        <div>
          <p className="text-[#AEAEAE]">Date:</p>
          <p>
            {userData?.user?.date_joined
              ? formatDate(userData.user.date_joined, "date")
              : "-"}
          </p>
        </div>
        <div>
          <p className="text-[#AEAEAE]">Contact number:</p>
          <p>
            {userData?.user?.phone !== undefined && userData?.user?.phone !== ""
              ? userData?.user?.phone
              : "-"}
          </p>
        </div>
        <div>
          <p className="text-[#AEAEAE]">Location:</p>

          {userData?.professional?.address_city &&
          userData?.professional?.address_state
            ? userData?.professional?.address_city +
              ", " +
              userData?.professional?.address_state
            : userData?.professional?.address_city
            ? userData?.professional?.address_city
            : userData?.professional?.address_state
            ? userData?.professional?.address_state
            : "-"}
        </div>
      </div>
    </div>
  );
};

export default OrderCreateUserDetails;
