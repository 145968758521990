import React, { useEffect, useMemo, useState } from "react";
import UpandDownArrow from "../../../assets/svg/dateIcon.svg";
import { useTable, usePagination, useSortBy } from "react-table";
import InputField from "../../../components/InputField";
import SearchIcon from "../../../assets/SearchIcon";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import ManagementTableLayout from "../../../components/MangementTable";
import PlusIcon from "../../../assets/PlusIcon";
import { formatIndianRupeesForDecimals } from "../../../utils/formatToIndianRupees";
import Pagination from "../../../components/Pagination";
import { useRecoilValue } from "recoil";
import { stateAtom } from "../../../atom";

const Table = ({
  page,
  setPage,
  pageLength,
  setPageLength,
  searchValue,
  setSearchValue,
  orderList,
  isLoading,
  totalCount,
  setTotalCount,
  setOrderStatus,
  orderStatusSummary,
}: any) => {
  const stateList = useRecoilValue(stateAtom);

  const COLUMN = [
    {
      id: "order_no",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center min-w-max">
          Order ID
        </div>
      ),
      accessor: "order_no",
      width: "10px",
      Cell: ({ value, row }: any) => (
        <Link to={`/orders/${row.original.id}`}>
          <div>{value || "-"}</div>
        </Link> // id needs to be checked, showing undefined
      ),
    },
    {
      id: "owner_name",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Name</div>
      ),
      accessor: "owner_name",
      Cell: ({ value, row }: any) => (
        <Link to={`/orders/${row?.original?.id}`}>
          <div className="underline underline-offset-2 hover:scale-105 hover:pl-3">
            {row.original.owner.name}
          </div>
        </Link>
      ),
    },
    {
      id: "address_delivery",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Location</div>
      ),
      accessor: "address_delivery",
      width: "13vw",
      Cell: ({ row }: any) => {
        const { address_delivery } = row.original;
        const city = address_delivery?.city;
        const state = address_delivery?.state;

        const location =
          city && state
            ? `${city}, ${
                stateList?.find((el: any) => el?.state_code === state)?.name
              }`
            : "-";

        return <div className="w-[200px] break-words">{location}</div>;
      },
    },
    {
      id: "owner_type",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Type</div>
      ),
      accessor: "owner_type",
      Cell: ({ value, row }: any) => <div>{row.original.owner.kind}</div>,
    },
    {
      id: "order_date",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-center items-center gap-2 px-2">
          <p>Date</p>
        </div>
      ),
      accessor: "order_date",
      Cell: ({ value, row }: any) => {
        // Parse the incoming date string
        const date = new Date(value);
        // Create a formatted date string in the "DD/MM/YYYY" format
        const formattedDate = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`;

        return <div>{value ? formattedDate : "-"}</div>;
      },
    },
    {
      id: "line_item_count",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-end items-center w-full">Total Item</div>
      ),
      accessor: "line_item_count",
      width: "7vw",
      Cell: ({ value }: any) => (
        <div className="w-full text-right">{value || "-"}</div>
      ),
    },
    {
      id: "grand_total",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-end items-center w-full">Total Amount</div>
      ),
      accessor: "grand_total",
      Cell: ({ value }: any) => (
        <div className="w-full text-right">
          {formatIndianRupeesForDecimals(parseFloat(value)) || "-"}
        </div>
      ),
    },
    {
      id: "payment_status",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center">Payment Status</div>
      ),
      accessor: "payment_status",
      Cell: ({ value }: { value: any }) => {
        const formattedValue = value ? value.replace(/_/g, " ") : "-";

        return (
          <div
            className={`${
              value === "PROCESSING"
                ? "bg-[#D4A15E]"
                : value === "REFUNDED"
                ? "bg-[#9747FF]"
                : value === "PARTIALLY_PAID"
                ? "bg-[#F1F8E9]"
                : value === "Refund fail"
                ? "bg-[#FFEBEE]"
                : value === "Refund init"
                ? "bg-[#FFF9C4]"
                : value === "COMPLETED"
                ? "bg-[#BFE2C1]"
                : value === "PAID"
                ? "bg-[#BFE2C1]"
                : value === "FAILED"
                ? "bg-[#FFB4A1]"
                : value === "UNPAID"
                ? "bg-[#E3F2FD]"
                : ""
            } py-0.5 my-0.5 rounded-md text-center`}
          >
            {formattedValue}
          </div>
        );
      },
    },

    {
      id: "status",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Order Status</div>
      ),
      accessor: "status",
      width: "15vw",
      Cell: ({ value }: { value: any }) => {
        const formattedValue = value ? value.replace(/_/g, " ") : "-";

        return (
          <div className="flex gap-2 items-center">
            <p
              className={`w-4 h-4 rounded-full px-1 py-0.5 ${
                value === "ONGOING"
                  ? "bg-[#FFD194] bg-opacity-20 text-[#D4A15E]"
                  : value === "COMPLETED"
                  ? "bg-[#EBFFF2] text-[#42B86D]"
                  : value === "PENDING"
                  ? "bg-[#FFD194] bg-opacity-20 text-[#D4A15E]"
                  : value === "AWAITING_TIMELINE"
                  ? "bg-[#DBD6F6] text-[#9747FF]"
                  : value === "AWAITING_TIMELINE_CONFIRMATION"
                  ? "bg-[#DBD6F6] text-[#9747FF]"
                  : value === "PROCESSING"
                  ? "bg-[#FFD194] bg-opacity-20 text-[#D4A15E]"
                  : value === "OUT_FOR_DELIVERY"
                  ? "bg-[#DBD6F6] text-[#9747FF]"
                  : value === "PARTIALLY_DELIVERED"
                  ? "bg-[#EBFFF2] text-[#42B86D]"
                  : value === "DELIVERED"
                  ? "bg-[#EBFFF2] text-[#42B86D]"
                  : value === "CANCELLED"
                  ? "bg-[#FFB4A1] text-[#ed4b3f]"
                  : value === "REFUNDED"
                  ? "bg-[#EBFFF2] text-[#42B86D]"
                  : value === "DRAFT"
                  ? "bg-[#EDEDED] text-[#8f8d8d]"
                  : "" // Replace with your default colors
              }`}
            ></p>
            <p> {formattedValue}</p>
          </div>
        );
      },
    },

    {
      id: "delivery_method",
      Header: ({ column }: { column: any }) => (
        <div className="flex justify-start items-center ">Delivery Method</div>
      ),
      accessor: "delivery_method",
    },
  ];

  const columns = useMemo(() => COLUMN, [orderList]);
  const data = useMemo(() => orderList || [], [orderList]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handlePageSizeChange = (size: any) => {
    setPageLength(size);
  };
  console.log(orderStatusSummary, "orderStatusSummary");

  return (
    <div className="h-full">
      <div className="flex flex-row justify-between items-center py-2 w-full px-3 h-[3rem] ">
        <div className="flex gap-4 w-[max-content]">
          <div className=" w-[235px]">
            <InputField
              onChange={(e: string) => setSearchValue(e)}
              placeholder="Search Order ID"
              value={searchValue}
              inputType={"number"}
              className="arrows-removed-from-input"
              rightIcon={<SearchIcon />}
            />
          </div>
          <div className="w-fit">
            <Link to={"/orders/create"}>
              <Button label="Create Order" leftIcon={<PlusIcon />} />
            </Link>
          </div>
        </div>

        <div className=" flex flex-row justify-end items-center gap-2 w-[65%]">
          <div className="inline-flex gap-3 items-center">
            <div
              className="flex items-center gap-2 border border-black rounded-md px-2  py-1 text-sm cursor-pointer hover:bg-black hover:text-white"
              onClick={() => setOrderStatus("DRAFT")}
            >
              <p className="font-medium">Drafts</p>
              {orderStatusSummary?.DRAFT?.count !== undefined && (
                <p className="bg-black px-1 py-0.5 rounded-full text-white text-xs text-center">
                  {orderStatusSummary.DRAFT.count}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="h-[calc(100%-5.5rem)] w-full relative -z-0">
        <ManagementTableLayout
          tableInstance={tableInstance}
          loading={isLoading}
        />
      </div>
      {isLoading ? (
        ""
      ) : (
        <div className="w-full">
          <Pagination
            currentPage={page}
            pageSize={pageLength}
            totalCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      )}
    </div>
  );
};

export default Table;
